import Header from "./components/Header";
import LandingPage from "./components/LandingPage";

import "./Edtech.css"
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
function App() {

  const RoutingData = () => {
    let routes = useRoutes([
      { path: "/", element: <LandingPage /> },
    ]);
    return routes;
  };


  return (
    <div className="App">
      <Header></Header>
       <Router>
      <RoutingData />
    </Router>
      
    </div>
  );
}

export default App;
