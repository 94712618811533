import { React, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import axiosService from '../services/api';
import Spinner from './Spinner';
import AsoftLogo from './logo2.png'
import { Mic, MicOff } from '@material-ui/icons';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

import AdjustIcon from '@material-ui/icons/Adjust';
// import Typo from 'typo-js';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TypeAnimation } from 'react-type-animation';
import Botzilla from './Botzilla.jpeg'

import quizME from './quiz.jpg'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserQueryImage from './Query.jpeg'
import Advertisment1 from './Advertisment6.jpg'
import Advertisment2 from './Advertisment4.jpeg'

import { Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor:'white',
      color:'black'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:'#fffbfb',
    // color:'white'

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:'white',
    paddingTop:'30px'
  },
}));


function ResponsiveDrawer(props) {

  
  const { window } = props;
  const [loader,setLoader]=useState(false)
  // const dictionary = new Typo('en_US');
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });
  const [hoverindex,sethoverindex]=useState('')
  const [hoverindexQuestion,sethoverindexQuestion]=useState('')
  const [editiconindex,setediticonindex]=useState('')
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [Disclaimer,setDisclaimer]=useState(false);
  const [opengame, setopengame] = useState(false);
  const [quizquestion,setquizquestion]=useState([])

  const [quizquestionog,setquizquestionog]=useState([

    {
      'question':'Largest indian city by territory',
      'options':['Mumbai','Delhi','Kolkata','Chennai'],
      'correct':'Delhi',
    },

    {
      'question':'Most visited state by domestic tourists',
      'options':['Delhi','Maharashtra','Tamil Nadu','Goa'],
      'correct':'Tamil Nadu',
    },

    {
      'question':'Where is valley of flowers located',
      'options':['Uttarakhand','Kerala','Jammu & Kashmir','Tripura'],
      'correct':'Uttarakhand',
    },

    {
      'question':'What is the largest temple in india',
      'options':['Sri Venkateswara Temple','Srirangam Ranganathaswamy','Somnath Temple','Kedarnath'],
      'correct':'Srirangam Ranganathaswamy',
    },

    {
      'question':'Actress with most filmfare award',
      'options':['Shabana Azmi','Smita Patil','Nargis','Jaya Bachan'],
      'correct':'Jaya Bachan',
    },

    {
      'question':'Richest female sports person',
      'options':['Mithali Raj','Harmanpreet Kaur','PV Sindhu','Jhulan Goswami'],
      'correct':'PV Sindhu',
    },

    {
      'question':'First woman to lead IT company',
      'options':['Roshni Nadar','Leena Nair','Kiran Mazumdar Shaw','Swati Ajay Piramal'],
      'correct':'Roshni Nadar',
    },

    {
      'question':'National Bird',
      'options':['Sparrow','Dove','Cuckoo','Peacock'],
      'correct':'Peacock',
    },

    {
      'question':'National Tree',
      'options':['Palm','Banyan','Mango','Lotus'],
      'correct':'Banyan',
    },
    {
      'question':'Biggest National Park',
      'options':['Hemis National Park','Desert National Park','Gangotri National Park','Namdapha National Park'],
      'correct':'Hemis National Park',
    },

  ])
  const [openfuture,setopenfuture]=useState(false)
  const [chatgptresponse,setchatgptresponse]=useState([])
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userQuery,setuserQuery]=useState('')
  const [randomNmber,setrandomNmber]=useState(Math.floor(Math.random() * 100001))
  const [provideAnimation,setprovideAnimation]=useState(false);
  const [frequentlyaskedQuestion,setfrequentlyaskedQuestion]=useState([{'question':'About Bapatla Institute'},{'question':'Who are all the management of Bapatla college of Arts and Sciences'},{'question':'What are the different courses and it categories offered by Bapatla college of Arts and Sciences'},{'question':'Library facility by Bapatla Engineering College'},{'question':'What are the different course and there fees price by THE BAPATLA COLLEGE OF ART'}])
  const [historyData, sethistoryData] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenfuture = () => {
    setopenfuture(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDisclaimer = () => {
    setDisclaimer(false);
  };

  const handleClosefuture = () => {
    setopenfuture(false);
  };


  const handleClosegame = () => {
    setopengame(false);
  };


  function displayText(text) {
    const words = text && text.split(' ');
    const lines = [];
    let line = '';
  
    words && words.forEach(word => {
      if ((line + ' ' + word).length > 100) {
        lines.push(line.trim());
        line = word;
      } else {
        line += ' ' + word;
      }
    });
    if (line !== '') {
      lines.push(line.trim());
    }
    return (
      <div>
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    );
  }
  function call_data(user_question)
  {
    
    setLoader(true)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+user_question+"&chat_id="+randomNmber).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':user_question,
                        'answer':response.data.query_response
                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
                setshowsuggestionupdates(false)

    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setshowsuggestionupdates(false)

                }
            )
  }
  function storeHisory(row)
  {
    callHstory()
    setprovideAnimation(false)
    setchatgptresponse([])
    setLoader(true)
    setrandomNmber(row.chat_id)
    axiosService.get("getrecords?chat_id="+row.chat_id).then(
            response => 
            {
                setchatgptresponse(response.data.query_response)
                setLoader(false)
                setshowsuggestionupdates(false)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setshowsuggestionupdates(false)

                }
            )
  }

  function deleteHistory(e,row)
  {
    setchatgptresponse([])
    deleteHistoryData(row.chat_id)
    callHstory()
  }
  
  function handleTextChange(e)
  {
    // const newText = event.target.value;
    // const correctedText = newText
    //   .split(' ')
    //   .map((word) => dictionary.check(word) ? word : dictionary.suggest(word)[0])
    //   .join(' ');
    setuserQuery(e.target.value);
  };
  
  

  function handleKeyPressEdit(event,index,user_query)
  {
    if (event.key === 'Enter') {
      event.preventDefault()
      // Check if the Shift key is also pressed
      if (event.shiftKey) {
        // Do nothing if Shift+Enter is pressed
        return;
      }

      // Call your function here if only Enter is pressed
      callApiEdit(event,index,user_query)
      
    }
  }

  
  function calluserdetails(e,row)
  {
    setshowsuggestionupdates(false)
    
    
    setLoader(true)
    console.log(randomNmber)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+row.updates+"&chat_id="+randomNmber).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':row.updates,
                        'answer':response.data.query_response
                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
    
  }
  function handleKeyPress(event)
  {
    if (event.key === 'Enter') {
      event.preventDefault()
      // Check if the Shift key is also pressed
      if (event.shiftKey) {
        // Do nothing if Shift+Enter is pressed
        return;
      }

      // Call your function here if only Enter is pressed
      callApi(event)
      setuserQuery('')
      setshowsuggestionupdates(false)
      
    }
  }

  function storeValue(e)
  {
    
  }
  function callApi(e)
  {
    setLoader(true)
    console.log(randomNmber)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdf?user_query="+userQuery+"&chat_id="+randomNmber).then(
            response => 
            {
                temp_records.push(
                    {
                        'question':userQuery,
                        'answer':response.data.query_response
                    }
                ) 
                setLoader(false)
                setchatgptresponse(temp_records)
    setuserQuery('')
    setprovideAnimation(true)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
  }

  function callApiEdit(event,index,user_query)
  {
    setLoader(true)
    console.log("user_query",user_query)
    console.log(randomNmber)
    let temp_records=[...chatgptresponse]
    axiosService.get("chatwithpdfedit?user_query="+user_query+"&chat_id="+randomNmber+"&old_question="+old_question).then(
            response => 
            {
                // temp_records.push(
                //     {
                //         'question':userQuery,
                //         'answer':response.data.query_response
                //     }
                // ) 
                temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                setchatgptresponse(temp_records)
    setediticonindex('')
    setprovideAnimation(false)
    setold_question(user_query)
            }
            )
            .catch(error => 
                {
                    console.log("error")
                }
            )
    
  }


  function callApitranslate(index,oldanswer,language,oldquestion)
  {
    setAnchorEl(null)
    setLoader(true)
    console.log(randomNmber)

    let data={
      'user_language':language,
      'chat_id':randomNmber,
      'oldanswer':oldanswer,
      'old_question':oldquestion

    }
    let temp_records=[...chatgptresponse]
    axiosService.post("chatwithpdftranslate",data).then(
            response => 
            {
              temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                console.log("chatgptresponse",temp_records)
                temp_records[index]['answer']=response.data.query_response
                setLoader(false)
                setchatgptresponse(temp_records)
                setediticonindex('')
                setprovideAnimation(false)
                setold_question(oldquestion)

            }
            )
            .catch(error => 
                {
                    console.log("error")
                    setAnchorEl(null)

                }
            )
    
  }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSpeechEnd = () => {
    // Preserve the transcript when speech ends
    console.log("ENd",transcript)
    setuserQuery(transcript);
  };
  const [isListening, setIsListening] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition({
   
  });

  const handleListen = () => {
    setIsListening(!isListening);
  };

  
  useEffect(() => {
    SpeechRecognition.onEnd = handleSpeechEnd; // Set up onEnd callback
  }, []); 
 

  const [buttonLabel,setbuttonLabel]=useState('')
  
  const [buttonshow,setbuttonshow]=useState('')
  function showcolor(option,answer)
  {
    if ( option.length>0)
    {
      if (option.includes(answer))
      {
        setbgcolor('green')
        setbuttonshow(answer)
      }
      else
      {
        setbgcolor('green')
        setbuttonshow(answer)
      }
      
    }

    setTimeout(() => {
      setopengame(false)
    }, 2000);
  }


  const [anchorEl, setAnchorEl] =useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };


  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popover' : undefined;


  const [bgcolor,setbgcolor]=useState('')
  


  function generateNewChat()
  {
    setchatgptresponse([])
    setrandomNmber(Math.floor(Math.random() * 100001))
    callHstory()
    setprovideAnimation(true)
    setshowsuggestionupdates(true)
  }

  function showGame()
  {
    let no=Math.floor(Math.random() * (9 - 0 + 1) + 0)
    let all_data=[]
    console.log("no",no)
    console.log(quizquestionog[no])
    
    all_data.push(quizquestionog[no])
    setquizquestion(all_data)
   
      setopengame(true)

  
  }
  function callHstory()
  {
    setLoader(true)
    axiosService.get("historyrecords").then(
        response => 
        {
           sethistoryData(response.data.query_response)
            setLoader(false)

        }
        )
        .catch(error => 
            {
                console.log("error")

            }
        )
  }

  function setnewanswer(e,question,index)
  {
    let oldresponse=[...chatgptresponse]
    chatgptresponse[index]['question']=e.target.value
    setchatgptresponse(oldresponse)
  }
  
  function sethoverindexresponse(index)
  {
    sethoverindex(index)
    sethoverindexQuestion('')
    setediticonindex('')
  }

  const [old_question,setold_question]=useState('')
  function setediticonindexquestion(index,question)
  {
    setediticonindex(index)
    sethoverindexQuestion('')
    sethoverindex('')
    setold_question(question)
  }

  function deleteHistoryData(chat_id)
  {
    setLoader(true)
    axiosService.get("deletehistoryrecords?chat_id="+chat_id).then(
        response => 
        {
            callHstory()
            setLoader(false)
        }
        )
        .catch(error =>  
            {
                console.log("error")
            }
        )
  }
  useEffect(()=>
  {
      callHstory()
  },[]
  )

  useEffect(()=>
  {
      showGame()
  },[]
  )

  function translateData(index,language,answer,old_question)
  {
    console.log("language",language)
    console.log("question",answer)
    callApitranslate(index,answer,language,old_question)
  }

  useEffect(() => {
    console.log("transcript",transcript)
    if (transcript!=='')
    {
      
      setuserQuery(transcript);
    }
    
  }, [transcript])


  useEffect(() => {
    if (isListening) {
      SpeechRecognition.startListening();
    } else {
      SpeechRecognition.stopListening();
    }})
  

  const drawer = (
      <div>
        {loader && <Spinner></Spinner>}
      <div  />
      <Grid item container lg={12} sm={12} md={12} style={{display:'flex',cursor:'pointer'}} >
        <Grid lg={8} md={8} sm={8}><Typography style={{paddingLeft:'50px',paddingBottom:'10px'}} >New Chat</Typography></Grid>
      <AddCircleIcon onClick={(e)=>generateNewChat(e)}/>
      <img  src={quizME} style={{width:'11%',height:'11%',marginLeft:'10px'}} onClick={(e)=>showGame()}></img>
      </Grid>
      <Divider />


      
      <List style={{maxHeight:'350px',minHeight:'350px',overflow:'auto'}}>
        {historyData && historyData.map((row,index)=>
        (
            <ListItem button key={row.question} style={{borderBottom:'1px solid lightgrey'}} >
            <ListItemText id="history" primary={row.question} onClick={(e)=>storeHisory(row)}/>
            <Tooltip title="Delete Chat">
            <DeleteIcon onClick={(e)=>deleteHistory(e,row)}></DeleteIcon>
            </Tooltip>
          </ListItem>
        ))}
      </List>


      <Typography style={{borderBottom:'1px solid lightgrey',padding:'0px 0px 0px 10px',fontWeight:'bold'}}>Most Popular</Typography>
      <List style={{maxHeight:'180px',minHeight:'180px',overflow:'auto'}}>
      {frequentlyaskedQuestion && frequentlyaskedQuestion.map((row,index)=>
        (
            <ListItem button key={row.question} style={{borderBottom:'1px solid lightgrey'}} >
            <ListItemText id="history" primary={row.question} onClick={(e)=>call_data(row.question)}/>
          </ListItem>
        ))}
      </List>
      <Divider />
      
      <div style={{ flexGrow: 1 }} />
      {/* <Typography style={{fontWeight:'bolder',paddingLeft:'10%'}}>Powered By </Typography> */}
      {/* <h6>Powered By</h6> */}
      <ListItem button >
        
      <img src={AsoftLogo} style={{width:'50%',height:'10%'}}></img>
      </ListItem>
           {/* <AccountCircle></AccountCircle> 
           <ListItemText style={{paddingLeft:'10px'}} primary="" />
           
          </ListItem> */}
          <ListItem button >
          <ExitToAppIcon></ExitToAppIcon>
          <ListItemText style={{paddingLeft:'10px'}} primary="Logout" />
           </ListItem>
      <Divider />
      
    </div>
  );
  const handleCopyToClipboard = (row) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        // If navigator.clipboard.writeText() is available, use it
        navigator.clipboard.writeText(row.answer)
            .then(() => {
                console.log('Text copied to clipboard:', row.answer);
            })
            .catch(error => {
                console.error('Error copying text to clipboard:', error);
            });
    } else {
        // Fallback method for non-supporting browsers
        const textArea = document.createElement('textarea');
        textArea.value = row.answer;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        console.log('Text copied to clipboard (fallback method):', row.answer);
    }
};

  const [showsuggestionupdates,setshowsuggestionupdates]=useState(true)
  const container = window !== undefined ? () => window().document.body : undefined;
  const [weeklyupdates,setweeklyupdates]=useState([{'updates':'Lab records are due before this weekend'},{'updates':'Weekly assignments must be submitted by Thursday 5 PM'}])
  const [futureupdates,setfutureupdates]=useState([{'updates':'Final Tuition Fee is Due my end of month'},{'updates':'Recruiting event schedules coming soon'}])
  const [suggestionupdates,setsuggestionupdates]=useState([{'key':'Academics Details','updates':'Provide academic details for Bapatla Engineering college'},
  {'key':'Admissions Details','updates':"What is the admisssion process details for Bapatla College"},
  {'key':'Career and Services','updates':"Open vacany available for teaching staffs in Bapatla College"},
  {'key':'Room and Boarding','updates':"Hostels facility available for Men's and Women's in Bapatla College"}

])
  

const [disclaimerupdates,setdisclaimerupdates]=useState([{'key':'General Information:','updates':' Botzilla is an automated system designed to provide information and assistance based on information requests. While we strive to provide accurate information through Botzilla, we cannot guarantee the accuracy, completeness, or up-to-date nature of the information provided. We recommend that you use responses as a starting point and verify the information through the public documentation sources.'},
  {'key':'User Responsibility:','updates':" Users of Botzilla bear sole responsibility for their interactions and reliance on the information provided. It is important to exercise caution and use your discretion while interpreting and acting upon the Botzilla's responses. We cannot be held liable for any actions, losses, or damages resulting from the use of Botzilla."},
  {'key':'Data Privacy and Security:','updates':" We prioritize the privacy and security of our users' information. Be aware that conversations may be reviewed by our content team to improve results. While we retain logs of these conversations for 30 days, this data is not used to train their models. Do not share any sensitive or personal information in your conversations."}
])


  return (
    <div className={classes.root}>

<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Upcoming Weekly Important Updates"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {weeklyupdates && weeklyupdates.map((row,index)=>
          (
            <li style={{padding:'10px'}}>{row.updates}</li>
          ))}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <Dialog
        open={Disclaimer}
        keepMounted
        onClose={handleCloseDisclaimer}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px',textAlign:'center'}}>{"Terms and Policies"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography style={{padding:'5px',fontSize:'14px'}}>The following disclaimer is intended to inform users of the limitations and usage guidelines of the Botzilla. Please read this disclaimer carefully before using it.</Typography>
          {disclaimerupdates && disclaimerupdates.map((row,index)=>
          (
            <p style={{padding:'5px',fontSize:'14px'}}><b>{row.key}</b>{row.updates}</p>
          ))}
            <Typography style={{padding:'5px',fontSize:'14px'}}>By using our Botzilla, you indicate your acceptance and understanding of the above disclaimer. If you do not agree with any part of this disclaimer, we recommend refraining from using Botzilla. For further assistance or inquiries, please contact Support at <u>info@asoftglobal.com.</u></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleCloseDisclaimer} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <Dialog
        open={openfuture}
        keepMounted
        onClose={handleClosefuture}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey',minWidth:'300px'}}>{"Upcoming Future Important Updates"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {futureupdates && futureupdates.map((row,index)=>
          (
            <li style={{padding:'10px'}}>{row.updates}</li>
          ))}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'1px solid lightgrey',paddingTop:'10px'}}>
         <Grid item container lg={5} sm={5} md={5}>
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7}>
          <Button onClick={handleClosefuture} variant="contained" color="primary">
            OK
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>



            
      <Dialog
        open={opengame}
        keepMounted
        onClose={handleClosegame}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >


        <DialogTitle id="alert-dialog-slide-title" style={{borderBottom:'1px solid lightgrey'}}>{"Please answer some quizz to get started"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {quizquestion && quizquestion.map((row,index)=>
          (
            <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
              <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
              <Typography style={{padding:'10px'}}>{row && row.question}</Typography>
  </Grid>
            

            {row && row.options.map((row1,index)=>(

                <Grid item container lg={6} md={6} sm={6} style={{padding:'10px',display:'grid'}}>
                  <Button variant="contained" style={{ backgroundColor: (buttonshow===row1?bgcolor:'')}} onClick={(e)=>showcolor(row1,row.correct)}>
              {row1}
            </Button>
            </Grid>
            ))}
            </Grid>
          ))}
          
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
         <Grid item container lg={12} sm={12} md={12} style={{display:'flex',borderTop:'2px solid lightgrey'}}>
         <Grid item container lg={5} sm={5} md={5} >
          
          </Grid>
          <Grid item container lg={7} sm={7} md={7} style={{paddingTop:'10px'}}>
          <Button onClick={handleClosegame} variant="contained" color="primary">
            Close
          </Button>
          </Grid>
         </Grid>
         
        </DialogActions>
      </Dialog>


      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        {/* <p style={{display:'contents'}}><img src={Botzilla} style={{width:'4%'}}></img></p>  */}
          <Typography variant="h6" noWrap style={{paddingLeft:'10px',display:'contents'}}>
         BOTZILLA
          </Typography>
         
          <img src={Botzilla} style={{width:'5%',height:'20%',marginLeft:'85%'}}></img>
         
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main>

     
      <p style={{float:'left',textDecoration:'underline',color:'#3a3acb',cursor:'pointer',paddingLeft:'10px'}} onClick={handleClickOpenfuture}>Future Updates</p>
        <p style={{float:'right',textDecoration:'underline',color:'#3a3acb',cursor:'pointer',paddingRight:'10px'}} onClick={handleClickOpen}>Weekly Updates</p>
        <Grid item container lg={12} sm={12} md={12} style={{display:'flex',backgroundColor:'white'}}>
        <Grid item container lg={1} sm={1} md={1} >
        {/* <img src={Botzilla} style={{width:'100%',padding:'10px'}}></img> */}
                </Grid>
                <Grid item container lg={7} sm={7} md={7} style={{minHeight:'480px',maxHeight:'480px',overflow:'auto',border:'1px solid lightgrey',borderRadius:'20px',display:'block'
              }}>


                    
                    
                
                {showsuggestionupdates && 

                <Grid item container lg={12} sm={12} md={12} style={{display:'flex',marginTop:'20%'}}>
                <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
                <Grid item container lg={5} sm={5} md={5}></Grid>
                      <Grid item container lg={6} sm={6} md={6} style={{display:'inline-table',paddingLeft:'10%'}}>

                      <img src={Botzilla} style={{width:'10%',height:'10%'}}></img>
                      </Grid>
                       
                      <Grid item container lg={5} sm={5} md={5}></Grid>
                      <Grid item container lg={6} sm={6} md={6} style={{paddingBottom:'20px',display:'block',fontSize:'14px',fontWeight:'bold'}}>How can I help you today?</Grid>
                       
                      </Grid>
                  {suggestionupdates && suggestionupdates.map((row,index)=>
                  (
                    <Grid item container lg={6} sm={6} md={6} style={{padding:'20px',display:'block',cursor:'pointer'}} onClick={(e)=>calluserdetails(e,row)}>

          <Card >
               
                  
                  <CardContent style={{padding:'2px'}}>
                  <Typography variant="body2" color="textSecondary" component="p" style={{textAlign:'center',fontWeight:'bolder'}}>
                    {row.key}
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.8',textAlign:'center',paddingTop:'10px'}}>
                    {row.updates}
                    </Typography>
                  </CardContent>
                {/* <CardActions>
                
                </CardActions> */}
              </Card>

                      </Grid>
                  ))}
                </Grid>
                }
                {console.log("chatgptresponse",chatgptresponse)}
                {chatgptresponse && chatgptresponse.map((row,index)=>
                (
                    <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
                <Grid item container lg={1} sm={1} md={1} style={{display:'block'}}><p style={{paddingLeft:'15px'}}><img src={UserQueryImage} style={{width:'61%',height:'1%'}}></img></p></Grid>

                <Grid item container lg={10} sm={10} md={10} style={{padding:'10px',display:'block'}}  onMouseEnter={(e)=>setediticonindexquestion(index,row.question)}>
                                        {/* <AccountCircleIcon></AccountCircleIcon>     */}


                                      {index===editiconindex &&   <Tooltip title="Edit Question"><EditIcon fontSize='small' onClick={(e)=>sethoverindexQuestion(index)} style={{cursor:'pointer'}} ></EditIcon></Tooltip>}                                        

                                        {index===hoverindexQuestion ?
                                        
                                        <TextField value={row.question} style={{display:'flex'}} onChange={(e)=>setnewanswer(e,row.question,index)} 
                                        multiline
                                        onKeyDown={(e)=>handleKeyPressEdit(e,index,row.question)}

                                        ></TextField>:<Typography style={{paddingTop:'2px',backgroundColor:'lightgrey',padding:'10px',borderRadius:'10px',fontSize:'13px'}} >{displayText(row.question)}</Typography>
                
                                        } 
                
                
                </Grid>
                <Grid item container lg={1} sm={1} md={1}></Grid>

                <Grid item container lg={1} sm={1} md={1} style={{display:'block',marginTop:'-5px'}}><p style={{padding:'1px'}}><img src={Botzilla} style={{width:'75%',height:'1%'}}></img></p></Grid>
              
               <Grid item container lg={10} sm={10} md={10} style={{padding:'10px',display:'block'} }
              // onClick={() => {navigator.clipboard.writeText(row.answer)}}
              onClick={(e)=>handleCopyToClipboard(row)}
              onMouseEnter={(e)=>sethoverindexresponse(index)}
               >
                    {/* <AccessibilityIcon></AccessibilityIcon> */}
                <Typography id="response" style={{paddingTop:'2px',backgroundColor:'lightblue',padding:'10px',borderRadius:'10px'}}
                 
                >

                              

                 {index===hoverindex && <>
                 
                 
                  <Tooltip title="">
                  <div>
                  <MoreVertIcon  aria-describedby={id} onClick={handleClick} fontSize='small' style={{cursor:'pointer',float:'inline-end'}}></MoreVertIcon>
      <Popover
        id={id}
        open={open1}
        anchorEl={anchorEl}
        onClose={handleCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography style={{paddingLeft:'25px',paddingRight:'25px',paddingTop:'10px',minWidth:'140px',cursor:'pointer'}} onClick={(e)=>translateData(index,"Telugu",row.answer,row.question)}>Telugu</Typography>
        <Typography style={{paddingLeft:'25px',paddingRight:'25px',paddingTop:'10px',paddingBottom:'10px',minWidth:'140px',cursor:'pointer'}} onClick={(e)=>translateData(index,"Hindi",row.answer,row.question)}>Hindi</Typography>
      </Popover>
    </div>
    </Tooltip>


                 {console.log("Answer",row.answer)}
                 <Tooltip title="Copy Response"><FileCopyIcon fontSize='small' style={{cursor:'pointer'}}></FileCopyIcon></Tooltip></> } 
                    { ((chatgptresponse.length-1)===index && provideAnimation) ?<TypeAnimation
            sequence={[row.answer,100]}
            // repeat={Infinity}
            style={{ fontSize: '1em', display: 'grid' ,whiteSpace: 'pre-wrap',wordWrap:'break-word'}}
            wrapper="div"
            speed={50, {type: "keyStrokeDelayInMs", value: 3,cursor:false}}
            /> :<Typography style={{paddingTop:'1px',fontSize:'13px'}} >{displayText(row.answer)}</Typography>}
                    </Typography>
                </Grid>

                <Grid item container lg={1} sm={1} md={1}></Grid>

                    </Grid>
                )
                )}
                </Grid>
                {/* <Grid item container lg={1} sm={1} md={1} >
                
                </Grid> */}

                <Grid item container lg={4} sm={4} md={4} style={{padding:'10px 10px 10px 30px',maxHeight:'200px',display:'flow'}} >
                {/* <img src={Botzilla} style={{width:'100%',padding:'10px'}}></img> */}
                <Card >
      <CardActionArea style={{
        backgroundImage: `url(${Advertisment1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '210px', // Adjust height as needed
        // You can add more background properties here
      }}>
        
        <CardContent>
       
          {/* <img src={Advertisment1}></img> */}
          <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.01'}}>
          BEC was established in 1981. The founder president for this institute is Yarlagadda Krishna Murthy. It is located in Bapatla district.
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
       
      </CardActions> */}
    </Card>

    <Card style={{marginTop:'10px'}}>
      <CardActionArea style={{
        backgroundImage: `url(${Advertisment2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '220px', // Adjust height as needed
        // You can add more background properties here
      }}>
        
        <CardContent>
       
          {/* <img src={Advertisment1}></img> */}
          <Typography variant="body2" color="textSecondary" component="p" style={{opacity:'0.01'}}>
          BEC was established in 1981. The founder president for this institute is Yarlagadda Krishna Murthy. It is located in Bapatla district.
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
       
      </CardActions> */}
    </Card>

    {/* <Typography style={{padding:'10px',fontSize:'14px'}} className='blink'>Disclaimer:  We strive to provide accurate information through Botzilla, we cannot guarantee the accuracy, completeness, or up-to-date nature of the information provided</Typography> */}
                </Grid>
                <Grid item container lg={12} sm={12} md={12} style={{display:'flex'}}>
        <Grid item container lg={1} sm={1} md={1} >
                </Grid>
                <Grid item container lg={7} sm={7} md={7} style={{display:'grid'}}>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" style={{paddingTop:'10px'}}>
          <OutlinedInput
            id="outlined-adornment-weight"
            // multiline
            spellCheck="true"
            value={userQuery}
          maxRows={2}
          onChange={(e)=>handleTextChange(e)}
          onKeyDown={(e)=>handleKeyPress(e)}
          endAdornment={
            <>
             <Button onClick={handleListen} color="primary">
              {isListening ? <Mic /> : <MicOff />}
            </Button>
            
              {/* <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={callApi}>
                Submit
              </InputAdornment> */}
            </>
          }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
          />
        </FormControl>
                </Grid>

                <Grid item container lg={4} sm={4} md={4} >
                </Grid>

                <Grid item container lg={1} sm={1} md={1} >
                </Grid>

                <Grid item container lg={10} sm={10} md={10} style={{paddingLeft:'2%',opacity:'0.5',lineHeight:'0'}}>
                  <p style={{display:'flex'}}>Version.0.1.1 - Botzilla can make mistakes.Consider checking important information. </p>
                  <p style={{textDecoration:'underline',fontWeight:'bold',cursor:'pointer'}} onClick={(e)=>setDisclaimer(true)}> Read Terms and Policies</p>
                </Grid>
                <Grid item container lg={3} sm={3} md={3} >
                </Grid>

               
                </Grid>
        </Grid>
      </main>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};
export default ResponsiveDrawer;
